/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslate } from '@tolgee/react';
import { GetStaticProps } from 'next';
import {
  AdFragment,
  BannerFragment,
  NewsListItemFragment,
  RankingFragment,
  TournamentListItemFragment,
  UnionFragment,
} from '@amf/shared/types/graphql';
import { sdk } from '@amf/shared/lib/graphQlClient';
import Button from '@amf/shared/components/button/Button';
import { getPartners, NavigationProps } from '@amf/shared/utils/navigation';
import { getLanguageLocale } from '@amf/shared/utils/languages';
import Slider from '@amf/shared/components/slider/Slider';
import Container from '@amf/shared/components/layout/Container';
import NewsPreviewList from '@amf/shared/components/news/NewsPreviewList';
import { getUnixTime } from 'date-fns';
import Ads from '@amf/shared/components/ad/Ads';

import IconCountPlayers from 'assets/svg/icon-count-players.svg';
import IconCountUnions from 'assets/svg/icon-count-unions.svg';
import IconCountTeams from 'assets/svg/icon-count-teams.svg';
import IconCountTournaments from 'assets/svg/icon-count-tournaments.svg';
import { AppLinks, ExternalLinks } from 'utils/links';
import Layout from 'components/layout/Layout';
import UnionSlider from 'components/union/UnionSlider';
import TournamentList from 'components/tournament/TournamentList';
import RankingTable from 'components/ranking/RankingTable';
import Meta from 'components/Meta';

function HomePageTiles() {
  const { t } = useTranslate(['malyfotbal', 'general']);
  return (
    <div className='HomePage__tiles'>
      <div className='HomePage__tilesItem'>
        <div className='HomePage__tilesItemIcon'>
          <Image src={IconCountPlayers} alt={t('home.tiles.players.alt')} width={56} height={56} />
        </div>
        <div className='HomePage__tilesItemContent'>
          <div className='HomePage__tilesItemValue'>60 000+</div>
          <div className='HomePage__tilesItemLabel'>{t('home.tiles.players.label')}</div>
        </div>
      </div>
      <div className='HomePage__tilesItem'>
        <div className='HomePage__tilesItemIcon'>
          <Image src={IconCountUnions} alt={t('home.tiles.unions.alt')} width={56} height={56} />
        </div>
        <div className='HomePage__tilesItemContent'>
          <div className='HomePage__tilesItemValue'>16</div>
          <div className='HomePage__tilesItemLabel'>{t('home.tiles.unions.label')}</div>
        </div>
      </div>
      <div className='HomePage__tilesItem'>
        <div className='HomePage__tilesItemIcon'>
          <Image src={IconCountTeams} alt={t('home.tiles.teams.alt')} width={56} height={56} />
        </div>
        <div className='HomePage__tilesItemContent'>
          <div className='HomePage__tilesItemValue'>1 462</div>
          <div className='HomePage__tilesItemLabel'>{t('home.tiles.teams.label')}</div>
        </div>
      </div>
      <div className='HomePage__tilesItem'>
        <div className='HomePage__tilesItemIcon'>
          <Image
            src={IconCountTournaments}
            alt={t('home.tiles.tournaments.alt')}
            width={56}
            height={56}
          />
        </div>
        <div className='HomePage__tilesItemContent'>
          <div className='HomePage__tilesItemValue'>136</div>
          <div className='HomePage__tilesItemLabel'>{t('home.tiles.tournaments.label')}</div>
        </div>
      </div>
    </div>
  );
}

function HomePageRegisterLinks() {
  const { t } = useTranslate(['malyfotbal', 'general']);
  return (
    <div className='HomePage__registerLinks'>
      <div className='HomePage__registerLinksList'>
        <Link href={AppLinks.registerPlayer}>
          <a className='HomePage__registerLinksItem HomePage__registerLinksItem--player'>
            <span>{t('home.register.player')}</span>
          </a>
        </Link>
        <Link href={AppLinks.registerTeam}>
          <a className='HomePage__registerLinksItem HomePage__registerLinksItem--team'>
            <span>{t('home.register.team')}</span>
          </a>
        </Link>
        <Link href={AppLinks.registerLeague}>
          <a className='HomePage__registerLinksItem HomePage__registerLinksItem--league'>
            <span>{t('home.register.league')}</span>
          </a>
        </Link>
        <Link href={AppLinks.registerReferee}>
          <a className='HomePage__registerLinksItem HomePage__registerLinksItem--referee'>
            <span>{t('home.register.referee')}</span>
          </a>
        </Link>
      </div>
    </div>
  );
}

interface HomePageProps extends NavigationProps {
  news: NewsListItemFragment[];
  banners: BannerFragment[];
  tournaments: TournamentListItemFragment[];
  unions: UnionFragment[];
  ads: {
    wide: AdFragment[];
    video: AdFragment[];
    square: AdFragment[];
  };
  rankings: RankingFragment[];
}

export default function HomePage({
  navigation,
  partners,
  news,
  banners,
  tournaments,
  unions,
  ads,
  rankings,
}: HomePageProps) {
  const { t } = useTranslate(['malyfotbal', 'general']);
  const [clientReady, setClientReady] = useState(false);
  useEffect(() => setClientReady(true), []);
  return (
    <div className='HomePage'>
      <Layout navigation={navigation} partners={partners}>
        <Meta />
        <Slider banners={banners} />
        <Container>
          <HomePageTiles />
          <div className='HomePage__columns'>
            <div>
              <div className='HomePage__header'>
                <h2 className='h1'>{t('home.coverLinks.title')}</h2>
              </div>
              <div className='HomePage__coverLinks'>
                <a
                  href={ExternalLinks.superliga}
                  target='_blank'
                  className='HomePage__coverLinksItem HomePage__coverLinksItem--superliga'
                  rel='noreferrer'
                >
                  <span className='HomePage__coverLinksItemTitle'>
                    {t('home.coverLinks.superliga')}
                  </span>
                </a>
                <Link href={AppLinks.cnp}>
                  <a className='HomePage__coverLinksItem HomePage__coverLinksItem--cnp'>
                    <span className='HomePage__coverLinksItemTitle'>
                      {t('home.coverLinks.cnp')}
                    </span>
                  </a>
                </Link>
              </div>
              {clientReady && <UnionSlider unions={unions} />}
            </div>
            <div>
              <div className='HomePage__header'>
                <h2 className='h1'>{t('home.tournaments.title')}</h2>
                {tournaments.length > 4 && (
                  <Link href={AppLinks.tournamentNew}>
                    <a className='HomePage__headerLink'>
                      <Button variant='blue'>{t('home.tournaments.add')}</Button>
                    </a>
                  </Link>
                )}
              </div>
              <TournamentList tournaments={tournaments} />
              <div className='HomePage__coverLinks HomePage__coverLinks--small'>
                <Link href={AppLinks.superfinale}>
                  <a className='HomePage__coverLinksItem HomePage__coverLinksItem--superfinale'>
                    <span className='HomePage__coverLinksItemTitle'>
                      {t('home.superfinale.title')}
                    </span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <Ads wide={ads.wide} video={ads.video} square={ads.square} />
          <NewsPreviewList
            title={t('home.news.title')}
            news={news}
            button={{
              title: t('home.news.all'),
              link: AppLinks.newsList,
              props: {
                variant: 'blue',
              },
            }}
          />
          <div className='HomePage__header HomePage__header--withOffset'>
            <h2 className='h1'>{t('home.ranking.title')}</h2>
          </div>
          <p className='HomePage__description'>{t('home.ranking.description')}</p>
          <RankingTable rankings={rankings} />
          <div className='HomePage__header HomePage__header--withOffset'>
            <h2 className='h1'>{t('home.register.title')}</h2>
          </div>
          <HomePageRegisterLinks />
        </Container>
      </Layout>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { tournaments, unions, banners, news, navigation, rankings } = await sdk.HomePagePage({
    union: process.env.NEXT_PUBLIC_UNION,
    locale: getLanguageLocale(locale!),
    dateFrom: getUnixTime(new Date()),
  });

  const { wide, video, square } = await sdk.AdAllList({ union: process.env.NEXT_PUBLIC_UNION });

  return {
    props: {
      navigation,
      news,
      banners,
      tournaments,
      unions,
      ads: {
        wide,
        video,
        square,
      },
      rankings,
      partners: await getPartners(),
    },
    revalidate: 10,
  };
};
